import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Link from '../Link'
import SocialLinks from '../SocialLinks'

// component
// ==========================================================================================
const ContactInfo = ({
  addressHeading,
  address,
  mapLink,
  contactHeading,
  phoneHeading,
  phone,
  faxHeading,
  fax,
  email,
}) => {
  return (
    <section className={classNames(css.wrap)}>
      <div className={classNames(css.inner)}>
        <div className="row bigger">
          <div className="col-sm-12 col-md-7 col-lg-6">
            <div className={css.addressSection}>
              <h5 className="small-title">{addressHeading}</h5>
              {address && (
                <Link link={{ url: mapLink }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: address.split(',').join('<br/>'),
                    }}
                  />
                </Link>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-6">
            <div className={css.addressSection}>
              <h5 className="small-title">{contactHeading}</h5>
              <div>
                {phoneHeading}:{' '}
                <Link link={{ url: `tel:${phone}` }}>{phone}</Link>
              </div>
              <div>
                {faxHeading}: {fax}
              </div>
              <div>
                <Link link={{ url: `mailto:${email}` }}>{email}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row bigger">
          <div className="col-sm-12 col-md-8 col-lg-12">
            <SocialLinks />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactInfo
