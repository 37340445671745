import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Wysiwyg from '../Wysiwyg'
import CircleImage from '../CircleImage'
import ContactInfo from '../ContactInfo'

// component
// ==========================================================================================
const Hero = (data) => {
  const {
    heroHeading,
    heroBody,
    heroImage1Sharp,
    heroImage2Sharp,
    isOnDarkBackground,
  } = data

  return (
    <section
      className={classNames(css.wrap, { [css.onDark]: isOnDarkBackground })}
    >
      <div className="row">
        <div
          className={classNames('col-sm-12 col-md-10 offset-lg-1', {
            'col-lg-6': heroImage1Sharp,
            'col-lg-8': !heroImage1Sharp,
          })}
        >
          <div className={css.textWrap}>
            <div className={css.titleWrap}>
              <Title tag="h1" content={heroHeading} />
            </div>
            <Wysiwyg content={heroBody} className="bigger" />
            <ContactInfo {...data} />
          </div>
          {heroImage2Sharp && (
            <div className={css.image2Positioner}>
              <div className={css.image2Wrap}>
                <CircleImage
                  image={heroImage2Sharp}
                  position="bottomRight"
                  className={css.image2}
                  d={0.05}
                />
              </div>
            </div>
          )}
        </div>
        {heroImage1Sharp && (
          <div className="col-sm-8 offset-sm-4 col-md-5 offset-md-7 col-lg-4 offset-lg-1 ">
            <div className={css.image1Wrap}>
              <CircleImage
                image={heroImage1Sharp}
                position="topLeft"
                className={css.image1}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
